<template>
  <div>
    <h1>All customers</h1>

    <div
      v-if="isTableLoading"
      class="text-center pt-3"
    >
      <b-spinner label="Loading..." />
    </div>

    <template v-else>
      <!-- search input -->
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>

      <vue-good-table
        :columns="columns"
        :rows="customers"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Bookings -->
          <span
            v-if="props.column.field === 'bookings'"
          >
            {{ props.row.bookings.join(', ') }}
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <b-button

              variant="primary"
              class="float-right"
              @click="sendPasswordReset(props.row.email)"
              size="sm"
            >
              <span>Send password reset</span>
            </b-button>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-end flex-wrap">
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </template>
      </vue-good-table>
    </template>
  </div>
</template>

<script>
import {
  BPagination,
  BFormGroup,
  BFormInput,
  VBModal,
  BSpinner,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import { toastSuccess, toastFailure } from '@/libs/toastification'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BSpinner,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      isTableLoading: true,
      searchTerm: '',
      columns: [
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Phone',
          field: 'phone',
        },
        {
          label: 'Email',
          field: 'email',
        },
        {
          label: 'Stripe Id',
          field: 'stripe_id',
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      pageLength: 25,
    }
  },
  computed: {
    ...mapGetters({
      customers: 'customers/customers',
    }),
  },
  async mounted() {
    await this.loadCustomers()
      .then(() => { this.isTableLoading = false })
      .catch(() => { toastFailure('Something went wrong.') })
  },
  methods: {
    ...mapActions({
      loadCustomers: 'customers/loadCustomers',
      recovery: 'customers/recovery',
    }),

    confirmationMessage(message) {
      return this.$bvModal.msgBoxConfirm(message, {
        title: 'Please Confirm',
        okVariant: 'primary',
        okTitle: 'Yes',
        cancelTitle: 'No',
      });
    },
    async sendPasswordReset(email){

      if (await this.confirmationMessage('Are you sure you want to send password recovery email to '+email+'?')) {
        await this.recovery({email: email,})
          .then(() => {
            toastSuccess('Recovery link has been send.')
          })
          .catch(() => {
            toastFailure('The given data was invalid.')
          })
          .finally(() => {
            this.isRecoveyLoading = false
          })
      }

    }
  },
}
</script>

<style>

</style>
